<template>
  <div class="information-banner">
    <div
      class="banner uk-alert danger col-all-12"
      uk-alert
    >
      <!-- <a
        class="uk-alert-close"
        uk-close
        @click="hideBanner"
      ></a> -->
      <span
        class="uk-icon"
        uk-icon="icon: warning; ratio: 1.5;"
      ></span>
      <div class="text col-mdmobile-10 col-smobile-10">
        <p>{{ $t('leafcad-is-disabled-1') }} (<a href="https://onclock.leafcad.com">https://onclock.leafcad.com</a>)</p>
        <p>{{ $t('leafcad-is-disabled-2') }}</p>
        <p>{{ $t('leafcad-is-disabled-3') }}</p>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/components/information-banner.less"></style>

<script>
import { mapState } from 'vuex'

export default {
  name: 'InformationBanner',
  computed: {
    ...mapState('informationBanner', ['globalMessage', 'isGlobalMessageShown'])
  },
  methods: {
    hideBanner: function() {
      this.$store.dispatch('informationBanner/setGlobalMessageVisible', false)
    }
  },
  async mounted() {
    if (!this.globalMessage) {
      await this.$store.dispatch('informationBanner/getGlobalMessage')
    }
  }
}
</script>
